// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyCDE-yTe1kDnVXEtuWtYCs6dYyo0By8fSw',
  authDomain: 'pjt-app-paper.firebaseapp.com',
  projectId: 'pjt-app-paper',
  storageBucket: 'pjt-app-paper.appspot.com',
  messagingSenderId: '396852997117',
  appId: '1:396852997117:web:ec33f243ff388e33de0794',
  measurementId: 'G-R2E7WC2T4R',
}
