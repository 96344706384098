import { SocialAuthCredentialDto } from '@/gateway/api/googleAuth'

const GOOGLE_AUTH_CREDENTIAL_KEY = 'google-auth-credential'

export const saveGoogleAuthCredential = (
  credential: SocialAuthCredentialDto
): void => {
  localStorage.setItem(GOOGLE_AUTH_CREDENTIAL_KEY, JSON.stringify(credential))
}

export const getGoogleAuthCredential = (): SocialAuthCredentialDto | null => {
  const value = localStorage.getItem(GOOGLE_AUTH_CREDENTIAL_KEY)
  return value ? JSON.parse(value) : null
}

export const removeGoogleAuthCredential = (): SocialAuthCredentialDto | null => {
  const value = getGoogleAuthCredential()
  localStorage.removeItem(GOOGLE_AUTH_CREDENTIAL_KEY)

  return value
}
