
















import { Component, Vue } from 'vue-property-decorator'
import { logout } from '@/gateway/api/auth'
import { userStore } from '@/store/user'
import { GoogleAuthApiClient } from '@/gateway/api/googleAuth'
import apiClient from '@/gateway/api/apiClient'
import { UserEntity } from '@/models/User'

type LoginState = 'loggedIn' | 'loggedOut' | 'error'

@Component({})
export default class Logout extends Vue {
  loginState: LoginState = 'loggedIn'

  get googleAuthApiClient(): GoogleAuthApiClient {
    return new GoogleAuthApiClient(apiClient)
  }

  get user(): UserEntity {
    return userStore.getUser
  }

  async created() {
    if (!userStore.getIsLoggedIn) {
      this.loginState = 'loggedOut'

      return
    }

    if (this.user.val.authType === 'google') {
      this.logout(() => this.googleAuthApiClient.signOut())
    } else {
      this.logout(logout)
    }
  }

  logout(logoutReq: () => Promise<void>) {
    logoutReq()
      .then(() => {
        userStore.reset()
        this.loginState = 'loggedOut'
      })
      .catch((e) => {
        console.error(e)
        userStore.reset()
        this.loginState = 'error'
      })
  }
}
