import { Gender } from '@/models/Gender'
import { GenderApiClient } from '@/gateway/api/gender'

export interface GenderRepository {
  list: () => Promise<Gender[]>
}

export class DefaultGenderRepository implements GenderRepository {
  constructor(private readonly client: GenderApiClient) {}

  list(): Promise<Gender[]> {
    return this.client.list().catch((e) => {
      console.error(e)
      throw new Error('Failed to fetch gender list')
    })
  }
}
