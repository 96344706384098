import axios from 'axios'
import { getGoogleAuthCredential } from '@/gateway/localStorage/googleAuth'

// axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.baseURL = '/api' // Firebase側の設定で、 /api 配下のリクエストを全てCloud Runへリダイレクトする
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'
// axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true

// Cloud RunでCORSエラーになるのを回避するためヘッダー名をカスタマイズ
const SP_AUTHORIZATION = 'SP-Authorization'

// middleware
axios.interceptors.request.use(
  function (config) {
    const socialAuthCredential = getGoogleAuthCredential()
    if (socialAuthCredential !== null) {
      config.headers[
        SP_AUTHORIZATION
      ] = `Bearer ${socialAuthCredential.access_token}`
    }
    console.log(config)

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

export default axios
