import { AxiosInstance } from 'axios'
import {
  removeGoogleAuthCredential,
  saveGoogleAuthCredential,
} from '@/gateway/localStorage/googleAuth'

type AuthFlowParam = 'signin' | 'signup'

interface GoogleAuthParams {
  token: string
  backend: string
  grant_type: string
  client_id: string
  client_secret: string
  auth_flow: AuthFlowParam
}

// 外部アカウントログイン後、アプリ内で認証するためのトークン情報
// ネイティブログインの場合のセッションIDと同等
export interface SocialAuthCredentialDto {
  access_token: string
  expires_in: number
  refresh_token: string
  scope: string
  token_type: string
}

export class GoogleAuthApiClient {
  private readonly basePath = '/auth'

  constructor(private readonly api: AxiosInstance) {}

  signInOrSignUp(data: GoogleAuthParams): Promise<SocialAuthCredentialDto> {
    removeGoogleAuthCredential()
    return this.api
      .post(`${this.basePath}/convert-token/`, data)
      .then((res) => {
        saveGoogleAuthCredential(res.data)

        return res.data
      })
      .catch((err) => {
        console.error('Error Google login', err)
        if (err.response.data.error === 'registered_with_other_service') {
          alert(
            'すでにパスワード認証でメールアドレスが登録済みです。メールアドレスとパスワードでログインしてください。'
          )
        }
        switch (err.response.data.error) {
          case 'registered_with_other_service':
            alert(
              'すでにパスワード認証でメールアドレスが登録済みです。メールアドレスとパスワードでログインしてください。'
            )
            break
          case 'app_account_not_found':
            alert('アカウントが登録されていません。サインアップしてください。')
            break
          case 'app_account_already_exists':
            alert('アカウントが登録済みです。ログインしてください。')
        }
      })
  }

  signOut(): Promise<void> {
    return this.api
      .post(`${this.basePath}/invalidate-sessions/`, {
        client_id: process.env.VUE_APP_DRF_CLIENT_ID,
      })
      .then(() => {
        return
      })
      .finally(() => {
        removeGoogleAuthCredential()
      })
  }
}
