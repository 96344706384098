/* eslint-disable camelcase */
import { UserEntity } from '@/models/User'
import apiClient from '@/gateway/api/apiClient'
import { convertDtoToUserEntity } from '@/repository/UserRepository'

export type AuthTypeRaw = 'DF' | 'GG'

export interface UserDto {
  date_joined: string
  email: string
  birthday: string | null
  organization: string | null
  gender: string | null
  profession_group: number | null
  interest_keywords: number[]
  interest_keyword_others: string | null
  first_name: string
  groups: string[]
  is_active: boolean
  is_staff: boolean
  is_superuser: boolean
  last_login: string
  last_name: string
  user_permissions: string[]
  uuid: string
  auth_type: AuthTypeRaw
}

export const checkLoggedIn = (): Promise<UserEntity> =>
  apiClient
    .get('/accounts/is-logged-in/')
    .then((response) => convertDtoToUserEntity(response.data.user))

export const updateUserInfo = (dto: UserDto): Promise<UserDto> =>
  apiClient.put('/accounts/users/', dto).then((response) => response.data)

export interface PasswordChange400Response {
  old_password?: string[]
  new_password?: string[]
}

export const changePassword = (passwords: {
  old_password: string
  new_password: string
}): Promise<void> => apiClient.put(`/accounts/password/change/`, passwords)

export const logout = (): Promise<void> => apiClient.get('/accounts/logout/')
