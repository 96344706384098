



















import { Component, Vue, Watch } from 'vue-property-decorator'
import SearchBox from '@/components/SearchBox.vue'
import PaperList from '@/components/PaperList.vue'
import axios from 'axios'
import Spinner from '@/components/Spinner.vue'

/**
 * FIXME: 仮で書いているだけ
 */

export interface Paper {
  title: string
}

@Component({
  components: {
    PaperList,
    SearchBox,
    Spinner,
  },
})
export default class SearchResult extends Vue {
  results: Paper[] = []

  created() {
    this.search()
  }

  @Watch('$route.query.keyword')
  reSearch() {
    this.search()
  }

  search() {
    // apiで論文検索する
    axios
      .get(`v3/paper/?query=${this.$route.query.keyword}`)
      .then((response) => {
        this.results = []
        JSON.parse(response.data).paper_search_result_list.forEach(
          (element: any) =>
            this.results.push({
              title: element.title,
            })
        )
      })
  }
}
