import { AxiosInstance } from 'axios'

export interface ProfessionGroupDto {
  id: number
  label: string
  order: number
}

export class ProfessionGroupApiClient {
  private basePath = '/accounts/masters/profession_group'
  constructor(private readonly api: AxiosInstance) {}
  list(): Promise<ProfessionGroupDto[]> {
    return this.api.get(`${this.basePath}/`).then((response) => response.data)
  }
}
