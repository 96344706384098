export function lpFunc() {
  var t = {
      191: function (t, e) {
        function o(t) {
          return (
            // eslint-disable-next-line no-func-assign
            (o =
              'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
                ? function (t) {
                    return typeof t
                  }
                : function (t) {
                    return t &&
                      'function' == typeof Symbol &&
                      t.constructor === Symbol &&
                      t !== Symbol.prototype
                      ? 'symbol'
                      : typeof t
                  }),
            o(t)
          )
        }

        !(function () {
          'use strict'

          function r() {
            var t = window,
              e = document
            if (
              !('scrollBehavior' in e.documentElement.style) ||
              !0 === t.__forceSmoothScrollPolyfill__
            ) {
              var r,
                l = t.HTMLElement || t.Element,
                n = {
                  scroll: t.scroll || t.scrollTo,
                  scrollBy: t.scrollBy,
                  elementScroll: l.prototype.scroll || c,
                  scrollIntoView: l.prototype.scrollIntoView,
                },
                i =
                  t.performance && t.performance.now
                    ? t.performance.now.bind(t.performance)
                    : Date.now,
                s =
                  ((r = t.navigator.userAgent),
                  new RegExp(['MSIE ', 'Trident/', 'Edge/'].join('|')).test(r)
                    ? 1
                    : 0)
              ;(t.scroll = t.scrollTo = function () {
                void 0 !== arguments[0] &&
                  (!0 !== a(arguments[0])
                    ? m.call(
                        t,
                        e.body,
                        void 0 !== arguments[0].left
                          ? ~~arguments[0].left
                          : t.scrollX || t.pageXOffset,
                        void 0 !== arguments[0].top
                          ? ~~arguments[0].top
                          : t.scrollY || t.pageYOffset
                      )
                    : n.scroll.call(
                        t,
                        void 0 !== arguments[0].left
                          ? arguments[0].left
                          : 'object' !== o(arguments[0])
                          ? arguments[0]
                          : t.scrollX || t.pageXOffset,
                        void 0 !== arguments[0].top
                          ? arguments[0].top
                          : void 0 !== arguments[1]
                          ? arguments[1]
                          : t.scrollY || t.pageYOffset
                      ))
              }),
                (t.scrollBy = function () {
                  void 0 !== arguments[0] &&
                    (a(arguments[0])
                      ? n.scrollBy.call(
                          t,
                          void 0 !== arguments[0].left
                            ? arguments[0].left
                            : 'object' !== o(arguments[0])
                            ? arguments[0]
                            : 0,
                          void 0 !== arguments[0].top
                            ? arguments[0].top
                            : void 0 !== arguments[1]
                            ? arguments[1]
                            : 0
                        )
                      : m.call(
                          t,
                          e.body,
                          ~~arguments[0].left + (t.scrollX || t.pageXOffset),
                          ~~arguments[0].top + (t.scrollY || t.pageYOffset)
                        ))
                }),
                (l.prototype.scroll = l.prototype.scrollTo = function () {
                  if (void 0 !== arguments[0])
                    if (!0 !== a(arguments[0])) {
                      var t = arguments[0].left,
                        e = arguments[0].top
                      m.call(
                        this,
                        this,
                        void 0 === t ? this.scrollLeft : ~~t,
                        void 0 === e ? this.scrollTop : ~~e
                      )
                    } else {
                      if (
                        'number' == typeof arguments[0] &&
                        void 0 === arguments[1]
                      )
                        throw new SyntaxError('Value could not be converted')
                      n.elementScroll.call(
                        this,
                        void 0 !== arguments[0].left
                          ? ~~arguments[0].left
                          : 'object' !== o(arguments[0])
                          ? ~~arguments[0]
                          : this.scrollLeft,
                        void 0 !== arguments[0].top
                          ? ~~arguments[0].top
                          : void 0 !== arguments[1]
                          ? ~~arguments[1]
                          : this.scrollTop
                      )
                    }
                }),
                (l.prototype.scrollBy = function () {
                  void 0 !== arguments[0] &&
                    (!0 !== a(arguments[0])
                      ? this.scroll({
                          left: ~~arguments[0].left + this.scrollLeft,
                          top: ~~arguments[0].top + this.scrollTop,
                          behavior: arguments[0].behavior,
                        })
                      : n.elementScroll.call(
                          this,
                          void 0 !== arguments[0].left
                            ? ~~arguments[0].left + this.scrollLeft
                            : ~~arguments[0] + this.scrollLeft,
                          void 0 !== arguments[0].top
                            ? ~~arguments[0].top + this.scrollTop
                            : ~~arguments[1] + this.scrollTop
                        ))
                }),
                (l.prototype.scrollIntoView = function () {
                  if (!0 !== a(arguments[0])) {
                    var o = y(this),
                      r = o.getBoundingClientRect(),
                      l = this.getBoundingClientRect()
                    o !== e.body
                      ? (m.call(
                          this,
                          o,
                          o.scrollLeft + l.left - r.left,
                          o.scrollTop + l.top - r.top
                        ),
                        'fixed' !== t.getComputedStyle(o).position &&
                          t.scrollBy({
                            left: r.left,
                            top: r.top,
                            behavior: 'smooth',
                          }))
                      : t.scrollBy({
                          left: l.left,
                          top: l.top,
                          behavior: 'smooth',
                        })
                  } else
                    n.scrollIntoView.call(
                      this,
                      void 0 === arguments[0] || arguments[0]
                    )
                })
            }

            function c(t, e) {
              ;(this.scrollLeft = t), (this.scrollTop = e)
            }

            function a(t) {
              if (
                null === t ||
                'object' !== o(t) ||
                void 0 === t.behavior ||
                'auto' === t.behavior ||
                'instant' === t.behavior
              )
                return !0
              if ('object' === o(t) && 'smooth' === t.behavior) return !1
              throw new TypeError(
                'behavior member of ScrollOptions ' +
                  t.behavior +
                  ' is not a valid value for enumeration ScrollBehavior.'
              )
            }

            function d(t, e) {
              return 'Y' === e
                ? t.clientHeight + s < t.scrollHeight
                : 'X' === e
                ? t.clientWidth + s < t.scrollWidth
                : void 0
            }

            function f(e, o) {
              var r = t.getComputedStyle(e, null)['overflow' + o]
              return 'auto' === r || 'scroll' === r
            }

            function p(t) {
              var e = d(t, 'Y') && f(t, 'Y'),
                o = d(t, 'X') && f(t, 'X')
              return e || o
            }

            function y(t) {
              for (; t !== e.body && !1 === p(t); ) t = t.parentNode || t.host
              return t
            }

            function u(e) {
              var o,
                r,
                l,
                n,
                s = (i() - e.startTime) / 468
              ;(n = s = s > 1 ? 1 : s),
                (o = 0.5 * (1 - Math.cos(Math.PI * n))),
                (r = e.startX + (e.x - e.startX) * o),
                (l = e.startY + (e.y - e.startY) * o),
                e.method.call(e.scrollable, r, l),
                (r === e.x && l === e.y) ||
                  t.requestAnimationFrame(u.bind(t, e))
            }

            function m(o, r, l) {
              var s,
                a,
                d,
                f,
                p = i()
              o === e.body
                ? ((s = t),
                  (a = t.scrollX || t.pageXOffset),
                  (d = t.scrollY || t.pageYOffset),
                  (f = n.scroll))
                : ((s = o), (a = o.scrollLeft), (d = o.scrollTop), (f = c)),
                u({
                  scrollable: s,
                  method: f,
                  startTime: p,
                  startX: a,
                  startY: d,
                  x: r,
                  y: l,
                })
            }
          }

          'object' === o(e)
            ? (t.exports = {
                polyfill: r,
              })
            : r()
        })()
      },
    },
    e = {}

  function o(r) {
    var l = e[r]
    if (void 0 !== l) return l.exports
    var n = (e[r] = {
      exports: {},
    })
    return t[r](n, n.exports, o), n.exports
  }

  ;(o.n = function (t) {
    var e =
      t && t.__esModule
        ? function () {
            return t.default
          }
        : function () {
            return t
          }
    return (
      o.d(e, {
        a: e,
      }),
      e
    )
  }),
    (o.d = function (t, e) {
      for (var r in e)
        o.o(e, r) &&
          !o.o(t, r) &&
          Object.defineProperty(t, r, {
            enumerable: !0,
            get: e[r],
          })
    }),
    (o.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e)
    }),
    (function () {
      'use strict'
      var t = o(191),
        e = o.n(t),
        r = function (t) {
          var e =
            arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 300
          ;(t.style.height = t.offsetHeight + 'px'),
            t.offsetHeight,
            (t.style.transitionProperty = 'height, margin, padding'),
            (t.style.transitionDuration = e + 'ms'),
            (t.style.transitionTimingFunction = 'ease'),
            (t.style.overflow = 'hidden'),
            (t.style.height = 0),
            (t.style.paddingTop = 0),
            (t.style.paddingBottom = 0),
            (t.style.marginTop = 0),
            (t.style.marginBottom = 0),
            setTimeout(function () {
              ;(t.style.display = 'none'),
                t.style.removeProperty('height'),
                t.style.removeProperty('padding-top'),
                t.style.removeProperty('padding-bottom'),
                t.style.removeProperty('margin-top'),
                t.style.removeProperty('margin-bottom'),
                t.style.removeProperty('overflow'),
                t.style.removeProperty('transition-duration'),
                t.style.removeProperty('transition-property'),
                t.style.removeProperty('transition-timing-function')
            }, e)
        },
        l = function (t) {
          var e =
            arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 300
          t.style.removeProperty('display')
          var o = window.getComputedStyle(t).display
          'none' === o && (o = 'block'), (t.style.display = o)
          var r = t.offsetHeight
          ;(t.style.overflow = 'hidden'),
            (t.style.height = 0),
            (t.style.paddingTop = 0),
            (t.style.paddingBottom = 0),
            (t.style.marginTop = 0),
            (t.style.marginBottom = 0),
            t.offsetHeight,
            (t.style.transitionProperty = 'height, margin, padding'),
            (t.style.transitionDuration = e + 'ms'),
            (t.style.transitionTimingFunction = 'ease'),
            (t.style.height = r + 'px'),
            t.style.removeProperty('padding-top'),
            t.style.removeProperty('padding-bottom'),
            t.style.removeProperty('margin-top'),
            t.style.removeProperty('margin-bottom'),
            setTimeout(function () {
              t.style.removeProperty('height'),
                t.style.removeProperty('overflow'),
                t.style.removeProperty('transition-duration'),
                t.style.removeProperty('transition-property'),
                t.style.removeProperty('transition-timing-function')
            }, e)
        }
      window.addEventListener('scroll', function () {
        var t = window.pageYOffset,
          e = document.getElementById('intro'),
          o = document.getElementById('header')
        e &&
          (t > t + e.getBoundingClientRect().top
            ? o.classList.add('changed')
            : o.classList.remove('changed'))
      }),
        (function () {
          var t = document.body,
            e = document.getElementById('drawer-trigger'),
            o = document.getElementById('c-overlay'),
            r = document.getElementById('drawer-menu').querySelectorAll('a')

          function l() {
            t.classList.contains('drawer-open')
              ? n()
              : (t.classList.add('drawer-open'), e.classList.add('is-active'))
          }

          function n() {
            t.classList.remove('drawer-open'), e.classList.remove('is-active')
          }

          e.addEventListener('click', l),
            o.addEventListener('click', l),
            r.forEach(function (t) {
              t.addEventListener('click', n)
            })
        })(),
        e().polyfill(),
        (function () {
          for (
            var t = document.querySelectorAll('a[href^="#"]'),
              e = function (e) {
                t[e].addEventListener('click', function (o) {
                  o.preventDefault()
                  var r = t[e].getAttribute('href'),
                    l =
                      document
                        .getElementById(r.replace('#', ''))
                        .getBoundingClientRect().top +
                      window.pageYOffset -
                      document.getElementById('header').clientHeight
                  window.scrollTo({
                    top: l,
                    behavior: 'smooth',
                  })
                })
              },
              o = 0;
            o < t.length;
            o++
          )
            e(o)
        })(),
        document.querySelectorAll('#faq .question').forEach(function (t) {
          var e = t.nextElementSibling
          t.addEventListener('click', function () {
            !(function (t) {
              var e =
                arguments.length > 1 && void 0 !== arguments[1]
                  ? arguments[1]
                  : 300
              'none' === window.getComputedStyle(t).display ? l(t, e) : r(t, e)
            })(e, 300),
              t.classList.toggle('open')
          })
        })
    })()
}
