import { AuthType, UserEntity } from '@/models/User'
import { AuthTypeRaw, updateUserInfo, UserDto } from '@/gateway/api/auth'

export interface UserRepository {
  update: (entity: UserEntity) => Promise<UserEntity>
}

export class DefaultUserRepository implements UserRepository {
  update(entity: UserEntity): Promise<UserEntity> {
    return updateUserInfo(convertUserEntityToDto(entity))
      .then((response) => {
        return convertDtoToUserEntity(response)
      })
      .catch((e) => {
        console.error(e)
        throw new Error('Failed to update user info')
      })
  }
}

export const convertRawToAuthType = (raw: AuthTypeRaw): AuthType => {
  if (raw === 'GG') return 'google'

  return 'default'
}

export const convertAuthTypeToRaw = (authType: AuthType): AuthTypeRaw => {
  if (authType === 'google') return 'GG'

  return 'DF'
}

export const convertUserEntityToDto = (entity: UserEntity): UserDto => {
  return {
    uuid: entity.id.value,
    first_name: entity.val.firstName,
    last_name: entity.val.lastName,
    email: entity.val.email,
    gender: entity.val.gender,
    birthday: entity.val.birthday.length ? entity.val.birthday : null,
    profession_group: entity.val.professionGroup,
    organization: entity.val.organization.length
      ? entity.val.organization
      : null,
    interest_keywords: entity.val.interestKeywords,
    interest_keyword_others: entity.val.interestKeywordOthers
      ? entity.val.interestKeywordOthers
      : null,
    groups: entity.val.groups,
    is_active: entity.val.isActive,
    is_staff: entity.val.isStaff,
    is_superuser: entity.val.isSuperUser,
    last_login: entity.val.lastLogin,
    date_joined: entity.val.dateJoined,
    user_permissions: entity.val.userPermissions,
    auth_type: convertAuthTypeToRaw(entity.val.authType),
  }
}

export const convertDtoToUserEntity = (raw: UserDto): UserEntity => ({
  id: { value: raw.uuid },
  val: {
    firstName: raw.first_name,
    lastName: raw.last_name,
    email: raw.email,
    gender: raw.gender,
    birthday: raw.birthday || '',
    professionGroup: raw.profession_group,
    organization: raw.organization || '',
    interestKeywords: raw.interest_keywords,
    interestKeywordOthers: raw.interest_keyword_others || '',
    groups: raw.groups,
    isActive: raw.is_active,
    isStaff: raw.is_staff,
    isSuperUser: raw.is_superuser,
    lastLogin: raw.last_login,
    dateJoined: raw.date_joined,
    userPermissions: raw.user_permissions,
    authType: convertRawToAuthType(raw.auth_type),
  },
})
