import Vue from 'vue'
import Vuex from 'vuex'
import { User } from '@/store/user'

Vue.use(Vuex)

interface StoreType {
  user: User
}

/**
 * 各モジュールはstore初期化後にダイナミックに登録するためmodulesを書かない
 * @see: https://github.com/championswimmer/vuex-module-decorators#dynamic-modules
 */
export default new Vuex.Store<StoreType>({})
