






































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { UserEntity } from '@/models/User'
import { Gender } from '@/models/Gender'
import { ProfessionGroupEntity } from '@/models/ProfessionGroup'
import { InterestKeywordEntity } from '@/models/InterestKeyword'

interface InputFields {
  email: string
  firstName: string
  lastName: string
  birthday: string
  gender: string | null
  professionGroup: number | null
  organization: string
  interestKeywords: number[]
  interestKeywordOthers: string
}

@Component({})
export default class AccountEditor extends Vue {
  @Prop({ required: true })
  user!: UserEntity

  @Prop({ required: true })
  genderList!: Gender[]

  @Prop({ required: true })
  professionGroupList!: ProfessionGroupEntity[]

  @Prop({ required: true })
  interestKeywordList!: InterestKeywordEntity[]

  inputs: InputFields = {
    email: '',
    firstName: '',
    lastName: '',
    birthday: '',
    gender: null,
    professionGroup: null,
    organization: '',
    interestKeywords: [],
    interestKeywordOthers: '',
  }
  emailIsValid: boolean | null = null
  genderOptions: string[] = []
  professionGroupOptions: { value: number; text: string }[] = []
  interestKeywordOptions: { value: number; text: string }[] = []

  created() {
    this.inputs = {
      email: this.user.val.email,
      firstName: this.user.val.firstName,
      lastName: this.user.val.lastName,
      birthday: this.user.val.birthday,
      gender: this.user.val.gender,
      professionGroup: this.user.val.professionGroup,
      organization: this.user.val.organization,
      interestKeywords: this.user.val.interestKeywords,
      interestKeywordOthers: this.user.val.interestKeywordOthers,
    }
    this.setGenderOptions()
    this.setProfessionGroupOptions()
    this.setInterestKeywordOptions()
  }

  get isNativeUser(): boolean {
    return this.user.val.authType === 'default'
  }

  setGenderOptions() {
    this.genderOptions = this.genderList.map((gender) => gender.id)
  }

  setProfessionGroupOptions() {
    this.professionGroupOptions = this.professionGroupList.map((elem) => {
      return {
        value: elem.id.value,
        text: elem.val.label,
      }
    })
  }

  setInterestKeywordOptions() {
    this.interestKeywordOptions = this.interestKeywordList.map((elem) => {
      return {
        value: elem.id.value,
        text: elem.val.label,
      }
    })
  }

  @Watch('email')
  validateEmail() {
    if (this.inputs.email.trim() === this.user.val.email) {
      this.emailIsValid = null
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.emailIsValid = re.test(this.inputs.email.trim())
    }
  }

  inputsAreValid(): boolean {
    return this.emailIsValid !== false
  }

  get someFieldsAreUpdated() {
    return (
      this.inputs.email.trim() !== this.user.val.email ||
      this.inputs.firstName.trim() !== this.user.val.firstName ||
      this.inputs.lastName.trim() !== this.user.val.lastName ||
      this.inputs.birthday?.trim() !== this.user.val.birthday ||
      this.inputs.gender !== this.user.val.gender ||
      this.inputs.professionGroup !== this.user.val.professionGroup ||
      this.inputs.organization.trim() !== this.user.val.organization ||
      JSON.stringify(this.inputs.interestKeywords.sort()) !==
        JSON.stringify(this.user.val.interestKeywords.sort()) ||
      this.inputs.interestKeywordOthers.trim() !==
        this.user.val.interestKeywordOthers
    )
  }

  onClickUpdate() {
    if (!this.someFieldsAreUpdated) return

    if (!this.inputsAreValid()) return

    this.update()
  }

  @Emit('update')
  update(): UserEntity {
    return {
      id: { value: this.user.id.value },
      val: {
        firstName: this.inputs.firstName,
        lastName: this.inputs.lastName,
        email: this.inputs.email,
        birthday: this.inputs.birthday,
        gender: this.inputs.gender,
        professionGroup: this.inputs.professionGroup,
        organization: this.inputs.organization,
        interestKeywords: this.inputs.interestKeywords,
        interestKeywordOthers: this.inputs.interestKeywordOthers,
        groups: this.user.val.groups,
        isActive: this.user.val.isActive,
        isStaff: this.user.val.isStaff,
        isSuperUser: this.user.val.isSuperUser,
        lastLogin: this.user.val.lastLogin,
        dateJoined: this.user.val.dateJoined,
        userPermissions: this.user.val.userPermissions,
        authType: this.user.val.authType,
      },
    }
  }
}
