














import { Component, Vue } from 'vue-property-decorator'
import SearchBox from '../components/SearchBox.vue'

@Component({
  components: {
    SearchBox,
  },
})
export default class Top extends Vue {
  get attentionPaperLink(): string {
    return encodeURI('/app/search?keyword=Attention Is All You Need')
  }
}
