

















































import { Component, Vue, Watch } from 'vue-property-decorator'
import apiClient from '@/gateway/api/apiClient'
import { userStore } from '@/store/user'
import { convertDtoToUserEntity } from '@/repository/UserRepository'
import { GoogleAuthApiClient } from '@/gateway/api/googleAuth'

@Component
export default class Login extends Vue {
  email = ''

  password = ''

  emailIsEntered: boolean | null = null

  passwordIsEntered: boolean | null = null

  async created(): Promise<void> {
    await this.setCsrf()
  }

  setCsrf(): Promise<void> {
    return apiClient.get('/accounts/set-csrf/')
  }

  login(): void {
    this.confirmInputValidations()
    apiClient
      .post('/accounts/login/', {
        email: this.email.trim(),
        password: this.password.trim(),
      })
      .then((response) => {
        userStore.setUser(convertDtoToUserEntity(response.data.user))
      })
      .then(() => {
        this.$router.push('/app')
      })
  }

  get googleAuthApiClient(): GoogleAuthApiClient {
    return new GoogleAuthApiClient(apiClient)
  }

  async loginWithGoogle() {
    const googleUser = await this.$gAuth.signIn()

    await this.googleAuthApiClient
      .signInOrSignUp({
        token: googleUser.getAuthResponse().access_token,
        backend: 'google-oauth2',
        grant_type: 'convert_token',
        client_id: process.env.VUE_APP_DRF_CLIENT_ID,
        client_secret: process.env.VUE_APP_DRF_CLIENT_SECRET,
        auth_flow: 'signin',
      })
      .then(() => {
        this.$router.replace('/app')
      })
  }

  @Watch('email')
  updateEmailInputState(): void {
    this.emailIsEntered = this.email.trim().length > 0
  }

  @Watch('password')
  updatePasswordInputState(): void {
    this.passwordIsEntered = this.password.trim().length > 0
  }

  allFormsAreEntered(): boolean {
    return this.email.trim().length > 0 && this.password.trim().length > 0
  }

  confirmInputValidations(): void {
    if (!this.emailIsEntered) {
      this.emailIsEntered = false
    }
    if (!this.passwordIsEntered) {
      this.passwordIsEntered = false
    }
  }
}
