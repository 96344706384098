






























import { BIcon } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { UserEntity } from '@/models/User'
import { userStore } from '@/store/user'
import {
  DefaultUserRepository,
  UserRepository,
} from '@/repository/UserRepository'
import AccountEditor from '@/components/AccountEditor.vue'
import {
  DefaultGenderRepository,
  GenderRepository,
} from '@/repository/GenderRepository'
import { GenderApiClient } from '@/gateway/api/gender'
import apiClient from '@/gateway/api/apiClient'
import {
  DefaultProfessionGroupRepository,
  ProfessionGroupRepository,
} from '@/repository/ProfessionGroupRepository'
import { ProfessionGroupApiClient } from '@/gateway/api/professionGroup'
import {
  DefaultInterestKeywordRepository,
  InterestKeywordRepository,
} from '@/repository/InterestKeywordRepository'
import { InterestKeywordApiClient } from '@/gateway/api/interestKeyword'
import { Gender } from '@/models/Gender'
import { ProfessionGroupEntity } from '@/models/ProfessionGroup'
import { InterestKeywordEntity } from '@/models/InterestKeyword'

@Component({
  components: {
    AccountEditor,
    BIcon,
  },
})
export default class Account extends Vue {
  isLoading = true
  genderList: Gender[] = []
  professionGroupList: ProfessionGroupEntity[] = []
  interestKeywordList: InterestKeywordEntity[] = []

  get user(): UserEntity {
    return userStore.getUser
  }

  get userRepo(): UserRepository {
    return new DefaultUserRepository()
  }

  get genderRepo(): GenderRepository {
    return new DefaultGenderRepository(new GenderApiClient(apiClient))
  }

  get professionGroupRepo(): ProfessionGroupRepository {
    return new DefaultProfessionGroupRepository(
      new ProfessionGroupApiClient(apiClient)
    )
  }

  get interestKeywordRepo(): InterestKeywordRepository {
    return new DefaultInterestKeywordRepository(
      new InterestKeywordApiClient(apiClient)
    )
  }

  setGenderList() {
    return this.genderRepo.list().then((list) => {
      this.genderList = list
    })
  }

  setProfessionGroupList() {
    return this.professionGroupRepo.list().then((list) => {
      this.professionGroupList = list
    })
  }

  setInerestKeywordList() {
    return this.interestKeywordRepo.listKeywordsByAdmin().then((list) => {
      this.interestKeywordList = list
    })
  }

  created() {
    this.isLoading = true
    Promise.all([
      this.setGenderList(),
      this.setProfessionGroupList(),
      this.setInerestKeywordList(),
    ]).finally(() => {
      this.isLoading = false
    })
  }

  update(user: UserEntity) {
    this.userRepo
      .update(user)
      .then((response: UserEntity) => {
        userStore.setUser(response)
        alert('ユーザー情報を更新しました。')
      })
      .catch((err) => {
        console.error(err)
        alert('エラーが発生しました。')
      })
  }
}
