








import { Component, Prop, Vue } from 'vue-property-decorator'
import PaperItem from '@/components/PaperItem.vue'
import { Paper } from '@/views/SearchResult.vue'

@Component({
  components: {
    PaperItem,
  },
})
export default class PaperList extends Vue {
  @Prop({ required: true })
  paperList!: Paper[]
}
