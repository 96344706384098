import { ProfessionGroupEntity } from '@/models/ProfessionGroup'
import {
  ProfessionGroupApiClient,
  ProfessionGroupDto,
} from '@/gateway/api/professionGroup'

export interface ProfessionGroupRepository {
  list: () => Promise<ProfessionGroupEntity[]>
}

export class DefaultProfessionGroupRepository
  implements ProfessionGroupRepository {
  constructor(private readonly client: ProfessionGroupApiClient) {}

  list(): Promise<ProfessionGroupEntity[]> {
    return this.client
      .list()
      .then((response) => response.map(convertDtoToProfessionGroupEntity))
      .catch((e) => {
        console.error(e)
        throw new Error('Failed to fetch profession group list')
      })
  }
}

const convertDtoToProfessionGroupEntity = (
  raw: ProfessionGroupDto
): ProfessionGroupEntity => {
  return {
    id: { value: raw.id },
    val: {
      label: raw.label,
      order: raw.order,
    },
  }
}
