<template>
  <div id="view">
    <div class="container">
      <div class="row">
        <div class="col">
          <h3>{{ $route.query.title }}</h3>
          <h6>ノードの大きさは引用数の多さを表します</h6>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center" style="width: 800px">
          <div id="cy"></div>
          <vue-loading
            type="spin"
            color="#7374df"
            :size="{ width: '100px', height: '100px' }"
            v-if="isLoading"
            style="z-index: 10000; margin-top: 300px"
          ></vue-loading>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cytoscape from 'cytoscape'
import klay from 'cytoscape-klay'
import firebase from 'firebase/app'
cytoscape.use(klay)
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import popper from 'cytoscape-popper'

cytoscape.use(popper)

import apiClient from '@/gateway/api/apiClient'
import { userStore } from '@/store/user'
import { NetworkConfig } from '@/components/view-model/network-config'
import { createSameElemArr } from '@/utils/util'

import { VueLoading } from 'vue-loading-template'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {
    VueLoading,
  },
})
export default class Cytoscape extends Vue {
  isLoading = true

  // TODO :NetworkConfig を付けると Unexpected token が発生する
  @Prop()
  networkConfig

  get referenceNumListStr() {
    return JSON.stringify(
      createSameElemArr(
        this.networkConfig.referenceWidth,
        this.networkConfig.referenceDepth
      )
    )
  }
  get citationNumListStr() {
    return JSON.stringify(
      createSameElemArr(
        this.networkConfig.citationWidth,
        this.networkConfig.citationDepth
      )
    )
  }
  drawNetwork() {
    this.isLoading = true
    apiClient
      .get('v3/graph/', {
        params: {
          query: this.$route.query.title,
          citation_lower_limit: this.networkConfig.citationLowerLimit,
          reference_num_list: this.referenceNumListStr,
          citation_num_list: this.citationNumListStr,
        },
      })
      .then((response) => {
        this.isLoading = false
        this.cy = cytoscape({
          container: document.getElementById('cy'),
          elements: JSON.parse(response.data),
          boxSelectionEnabled: false,
          autounselectify: true,
          style: [
            {
              selector: 'node',
              style: {
                // prettier-ignore
                'background-color': 'data(color)',
                // prettier-ignore
                'font-family': 'sans-serif',
                // prettier-ignore
                'font-size': '4px',
                // prettier-ignore
                'color': '#4169e1',
                // prettier-ignore
                'label': 'data(label)',
                // prettier-ignore
                'width': 'data(width)',
                // prettier-ignore
                'height': 'data(height)',
                // prettier-ignore
                'background-opacity': 'data(opacity)',
              },
            },
            {
              selector: 'edge',
              style: {
                // prettier-ignore
                'width': 'data(width)',
                // prettier-ignore
                'line-color': 'data(color)',
                // prettier-ignore
                'target-arrow-color': 'data(color)',
                // prettier-ignore
                'target-arrow-shape': 'triangle',
                // prettier-ignore
                'curve-style': 'bezier',
              },
            },
          ],
          layout: {
            name: 'klay',
            spacing: 30,
            animate: true,
            edgeSpacingFactor: 1.0,
          },
        })
        this.cy.on('tap', 'node', function () {
          try {
            // your browser may block popups
            window.open(this.data('link'))
          } catch (e) {
            // fall back on url change
            window.location.href = this.data('link')
          }
        })
        this.cy.nodes().forEach((node) => {
          node.on('mouseover', function () {
            let ref = node.popperRef()
            let dummyDomEle = document.createElement('div')
            let tip = new tippy(dummyDomEle, {
              getReferenceClientRect: ref.getBoundingClientRect,
              trigger: 'manual',
              placement: 'bottom',
              content: () => {
                let content = document.createElement('div')
                content.innerHTML = node.data('tooltip')
                return content
              },
            })
            node.tippy = tip
            tip.show()
          })
          node.on('mouseout', function (event) {
            event.target.tippy.destroy()
          })
          node.on('click', () => {
            firebase.analytics().logEvent('view_paper', {
              userId: userStore.getUser.val.email,
              paperTitle: node.data('tooltip'),
              paperURL: node.data('link'),
            })
          })
        })
      })
  }
  mounted() {
    firebase.analytics().logEvent('view_network', {
      userId: userStore.getUser.val.email,
      paperTitle: this.$route.query.title,
    })
    this.drawNetwork()
  }
}
</script>
<style scoped>
#cy {
  text-align: left !important;
  position: absolute;
  width: 100%;
  height: 800px;
  background-color: #f9f9f9;
}
</style>
