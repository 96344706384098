



















// prettier-ignore
import {Component, Vue} from 'vue-property-decorator'
import Network from '@/components/Network.vue'
import NetworkConfigMenu from '@/components/NetworkConfigMenu.vue'
import { NetworkConfig } from '@/components/view-model/network-config'

export interface Node {
  id: string
  label: string
  link: string
  opacity: number
  tooltip: string
  width: string
  height: string
  color: string
}

export interface Edge {
  id: string
  source: string
  target: string
  width: string
  color: string
}

export interface Graph {
  nodes: Node[]
  edges: Edge[]
}

@Component({
  components: {
    Network,
    NetworkConfigMenu,
  },
})
export default class NetworkVis extends Vue {
  initialConfig: NetworkConfig = {
    citationLowerLimit: 20,
    referenceWidth: 3,
    referenceDepth: 3,
    citationWidth: 3,
    citationDepth: 3,
  }

  currentConfig: NetworkConfig = this.initialConfig

  get refs(): any {
    return this.$refs
  }

  onUpdateConfig(config: NetworkConfig) {
    this.currentConfig = config
    this.refs.network.drawNetwork()
  }
}
