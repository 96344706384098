






import { Component, Vue } from 'vue-property-decorator'
import { userStore } from '@/store/user'

@Component({})
export default class Top extends Vue {
  isLoggedIn(): boolean {
    return userStore.getIsLoggedIn
  }
}
