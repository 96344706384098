





























































import { userStore } from '@/store/user'
import { Component, Vue } from 'vue-property-decorator'
import { GoogleAuthApiClient } from '@/gateway/api/googleAuth'
import apiClient from '@/gateway/api/apiClient'
import { BIcon } from 'bootstrap-vue'

@Component({
  components: {
    BIcon,
  },
})
export default class AppMain extends Vue {
  isLoggedIn = false
  isNativeAccount = false

  mounted() {
    this.checkLoginState()
    this.checkIsNativeAccountUser()
  }

  beforeUpdate() {
    this.checkLoginState()
    this.checkIsNativeAccountUser()
  }

  checkLoginState() {
    this.isLoggedIn = userStore.getIsLoggedIn
  }

  checkIsNativeAccountUser() {
    this.isNativeAccount =
      this.isLoggedIn && userStore.getUser?.val.authType === 'default'
  }

  get googleAuthApiClient(): GoogleAuthApiClient {
    return new GoogleAuthApiClient(apiClient)
  }
}
