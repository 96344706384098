










































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { NetworkConfig } from '@/components/view-model/network-config'
import { createSameElemArr } from '@/utils/util'

@Component({})
export default class NetworkConfigMenu extends Vue {
  @Prop({ required: true })
  initialConfig!: NetworkConfig

  currentConfig: NetworkConfig = this.initialConfig

  @Emit('update')
  onClickDraw() {
    return this.currentConfig
  }
}
