import { Gender } from '@/models/Gender'
import { AxiosInstance } from 'axios'

export class GenderApiClient {
  private basePath = '/accounts/masters/gender'
  constructor(private readonly api: AxiosInstance) {}
  list(): Promise<Gender[]> {
    return this.api.get(`${this.basePath}/`).then((response) => response.data)
  }
}
