






















import { Component, Vue, Watch } from 'vue-property-decorator'
import firebase from 'firebase/app'
import { userStore } from '@/store/user'

@Component({})
export default class SearchBox extends Vue {
  isEntered = false
  searchString = ''

  @Watch('searchString')
  updateInputState() {
    this.isEntered = this.searchString.trim().length > 0
  }

  validateInput(): boolean {
    return this.isEntered
  }

  search() {
    if (!this.validateInput()) return

    this.$router.push(
      encodeURI(`/app/search?keyword=${this.searchString}`),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    )

    firebase.analytics().logEvent('search', {
      userId: userStore.getUser.val.email,
      keyword: this.searchString,
    })
  }

  triggerSearchByEnterKey(event: any) {
    // 日本語入力中のEnterキー操作を無効にする
    if (event.keyCode !== 13 || !this.validateInput()) return

    this.search()
  }
}
