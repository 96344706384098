




















































import { Component, Vue } from 'vue-property-decorator'
import { changePassword, PasswordChange400Response } from '@/gateway/api/auth'

interface InputForm {
  currentPass: string | null
  newPass1: string | null
  newPass2: string | null
}

const emptyForm: InputForm = {
  currentPass: null,
  newPass1: null,
  newPass2: null,
}

const notOnlyNumbers = (s: string): boolean => !s.match(/^[0-9]+$/)

@Component({})
export default class PasswordChange extends Vue {
  form: InputForm = emptyForm

  get currentPassIsEntered(): boolean | null {
    // 初期表示時はバリデーションエラーを出さない
    if (this.form.currentPass === null) return null

    return this.form.currentPass.trim().length > 0
  }

  get newPass1IsEntered(): boolean | null {
    if (this.form.newPass1 === null) return null

    return (
      this.form.newPass1.trim().length > 7 &&
      notOnlyNumbers(this.form.newPass1.trim())
    )
  }

  get newPass2IsEntered(): boolean | null {
    if (this.form.newPass2 === null) return null

    return (
      this.form.newPass2.trim().length > 7 &&
      notOnlyNumbers(this.form.newPass2.trim())
    )
  }

  get newPasswordIsValid(): boolean | null {
    if (this.form.newPass1 === null || this.form.newPass2 === null) return null

    return this.form.newPass1 === this.form.newPass2
  }

  allFieldsAreValid(): boolean {
    return (
      this.currentPassIsEntered === true &&
      this.newPass1IsEntered === true &&
      this.newPass2IsEntered === true &&
      this.newPasswordIsValid === true
    )
  }

  updatePassword() {
    if (!this.allFieldsAreValid()) return

    // 上でnullチェック済みなのでnon-null-assertionを許可
    changePassword({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      old_password: this.form.currentPass!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      new_password: this.form.newPass1!,
    })
      .then(() => {
        alert('パスワードを変更しました。ログイン画面に戻ります。')
        this.resetForm()
        this.$router.replace('/app/login')
      })
      .catch((err) => {
        const reason: PasswordChange400Response = err.response.data
        console.log(reason)
        if (!reason.old_password && !reason.new_password) {
          alert('エラーが発生しました。ログイン画面に戻ります。')
          this.resetForm()
          this.$router.replace('/app/login')
        }

        let msg = ''
        msg += reason.old_password ? reason.old_password[0] : ''
        msg += reason.new_password ? reason.new_password[0] : ''
        alert(msg)
      })
  }

  resetForm() {
    this.form.currentPass = null
    this.form.newPass1 = null
    this.form.newPass2 = null
  }
}
