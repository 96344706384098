import { InterestKeywordEntity } from '@/models/InterestKeyword'
import { Id } from '@/models/Entity'
import {
  InterestKeywordApiClient,
  InterestKeywordDto,
  InterestKeywordListQuery,
} from '@/gateway/api/interestKeyword'

export interface InterestKeywordRepository {
  list: (query: InterestKeywordListQuery) => Promise<InterestKeywordEntity[]>

  // 管理者が設定したキーワード一覧
  listKeywordsByAdmin: () => Promise<InterestKeywordEntity[]>

  get: (id: Id<number>) => Promise<InterestKeywordEntity>
  create: (entity: InterestKeywordEntity) => Promise<InterestKeywordEntity>
}

export class DefaultInterestKeywordRepository
  implements InterestKeywordRepository {
  constructor(private readonly client: InterestKeywordApiClient) {}

  list(query: InterestKeywordListQuery = {}): Promise<InterestKeywordEntity[]> {
    return this.client
      .list(query)
      .then((response) => response.map(convertDtoToInterestKeywordEntity))
      .catch((e) => {
        console.error(e)
        throw new Error('Failed to fetch interest keyword list')
      })
  }

  listKeywordsByAdmin(): Promise<InterestKeywordEntity[]> {
    return this.list({ by_user: false })
  }

  get(id: Id<number>): Promise<InterestKeywordEntity> {
    return this.client
      .get(id.value)
      .then(convertDtoToInterestKeywordEntity)
      .catch((e) => {
        console.error(e)
        throw new Error(`Failed to fetch interest keyword, ID=${id.value}`)
      })
  }

  create(entity: InterestKeywordEntity): Promise<InterestKeywordEntity> {
    return this.client
      .create(convertInterestKeywordEntityToDto(entity))
      .then(convertDtoToInterestKeywordEntity)
      .catch((e) => {
        console.error(e)
        throw new Error(
          `Failed to create interest keyword, label="${entity.val.label}"`
        )
      })
  }
}

const convertDtoToInterestKeywordEntity = (
  raw: InterestKeywordDto
): InterestKeywordEntity => {
  return {
    id: { value: raw.id },
    val: {
      label: raw.label,
      order: raw.order,
      byUser: raw.by_user,
    },
  }
}

const convertInterestKeywordEntityToDto = (
  entity: InterestKeywordEntity
): InterestKeywordDto => {
  return {
    id: entity.id.value,
    label: entity.val.label,
    order: entity.val.order,
    by_user: entity.val.byUser,
  }
}
