import { AxiosInstance } from 'axios'

export interface InterestKeywordDto {
  id: number
  label: string
  order: number
  by_user: boolean
}

export interface InterestKeywordListQuery {
  by_user?: boolean
}

export class InterestKeywordApiClient {
  private basePath = '/accounts/interest_keyword'

  constructor(private readonly api: AxiosInstance) {}

  list(query: InterestKeywordListQuery = {}): Promise<InterestKeywordDto[]> {
    return this.api
      .get(`${this.basePath}/`, { params: query })
      .then((response) => response.data)
  }

  get(id: number): Promise<InterestKeywordDto> {
    return this.api
      .get(`${this.basePath}/${id}`)
      .then((response) => response.data)
  }

  create(raw: InterestKeywordDto): Promise<InterestKeywordDto> {
    return this.api
      .post(`${this.basePath}/`, raw)
      .then((response) => response.data)
  }
}
