import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import Vue from 'vue'
import GAuth from 'vue-google-oauth2'
import VueHead from 'vue-head'
import App from './App.vue'
import router from './router'
import store from './store'
import { firebaseConfig } from '@/firebase'

firebase.initializeApp(firebaseConfig)
firebase.analytics()

Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Google auth
const gAuthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account',
  fetch_basic_profile: true,
}
Vue.use(GAuth, gAuthOption)

Vue.use(VueHead)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Lp = require('./lp')
Lp.lpFunc()
