

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Paper } from '@/views/SearchResult.vue'

@Component({})
export default class PaperItem extends Vue {
  @Prop({ required: true })
  paper!: Paper
}
