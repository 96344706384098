















































import { Component, Vue } from 'vue-property-decorator'
import apiClient from '@/gateway/api/apiClient'
import { GoogleAuthApiClient } from '@/gateway/api/googleAuth'

@Component
export default class SignUp extends Vue {
  policyAgreement = false

  async created(): Promise<void> {
    await this.setCsrf()
  }

  setCsrf(): Promise<void> {
    return apiClient.get('/accounts/set-csrf/')
  }

  get agreedToPolicy(): boolean {
    return this.policyAgreement
  }

  get googleAuthApiClient(): GoogleAuthApiClient {
    return new GoogleAuthApiClient(apiClient)
  }

  async signUpWithGoogle() {
    const googleUser = await this.$gAuth.signIn()

    await this.googleAuthApiClient
      .signInOrSignUp({
        token: googleUser.getAuthResponse().access_token,
        backend: 'google-oauth2',
        grant_type: 'convert_token',
        client_id: process.env.VUE_APP_DRF_CLIENT_ID,
        client_secret: process.env.VUE_APP_DRF_CLIENT_SECRET,
        auth_flow: 'signup',
      })
      .then(() => {
        this.$router.replace('/app')
      })
  }
}
